import React from "react"
import Marquee from "react-smooth-marquee"
import "./marquee.css"

export default function AboutMarquee() {
  return (
    // <section className="relative bg-white py-16">
    //   <h4 className="marquee">
    //     <span>
    //       – BUILT IN A CLOSED ENVIRONMENT – ENVIRONMENTALLY RESPONSIBLE –
    //       REDUCED WASTE – MODERN DESIGN, QUALITY CONSTRUCTION – QUICK
    //       TURNAROUND, SINGLE DAY INSTALLATION – PREFABRICATED & DELIVERED
    //       DIRECTLY TO YOU –
    //     </span>
    //   </h4>
    // </section>

    <section className="relative bg-white py-16">
      <Marquee>
        <div className="flex">
          <h4 className="px-2 flex-none">
            <span> – </span>
          </h4>
          <h4 className="px-10 flex-none">BUILT IN A CLOSED ENVIRONMENT</h4>
          <h4 className="px-2 flex-none">
            <span> – </span>
          </h4>
          <h4 className="px-10 flex-none">ENVIRONMENTALLY RESPONSIBLE</h4>
          <h4 className="px-2 flex-none">
            <span> – </span>
          </h4>
          <h4 className="px-10 flex-none">REDUCED WASTE</h4>
          <h4 className="px-2 flex-none">
            <span> – </span>
          </h4>
          <h4 className="px-10 flex-none">
            MODERN DESIGN, QUALITY CONSTRUCTION
          </h4>
          <h4 className="px-2 flex-none">
            <span> – </span>
          </h4>
          <h4 className="px-10 flex-none">
            QUICK TURNAROUND, SINGLE DAY INSTALLATION
          </h4>
          <h4 className="px-2 flex-none">
            <span> – </span>
          </h4>
          <h4 className="px-10 flex-none">
            PREFABRICATED & DELIVERED DIRECTLY TO YOU
          </h4>
          <h4 className="px-2 flex-none">
            <span> – </span>
          </h4>
        </div>
      </Marquee>
    </section>

    // <section className="relative bg-white py-16">
    //   <Marquee>
    //     <div>
    //       <h4>
    //         <span className="px-12">BUILT IN A CLOSED ENVIRONMENT</span>{" "}
    //         <span className="px-12">ENVIRONMENTALLY RESPONSIBLE</span>
    //         <span className="px-12">
    //           REDUCED WASTE – MODERN DESIGN, QUALITY CONSTRUCTION
    //         </span>
    //         <span className="px-12">
    //           QUICK TURNAROUND, SINGLE DAY INSTALLATION
    //         </span>
    //         <span className="px-12">
    //           PREFABRICATED & DELIVERED DIRECTLY TO YOU
    //         </span>
    //       </h4>
    //     </div>
    //   </Marquee>
    // </section>
  )
}
