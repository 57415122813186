import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import Image from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroSection from "../components/hero-section"
import CallToActionQualify from "../components/call-to-action-qualify/call-to-action-qualify"
import Marquee from "../components/marquee/marquee"
import { saveContactForm } from "../global/firebase"
import LottieTest from "../components/home/home-loader-test"
import LoadingSpinner from "../components/loading-spinner"
import StickyMobileCta from "./../components/call-to-action-qualify/sticky-mobile-cta"

const initialForm = {
  name: "",
  email: "",
  phone: "",
  message: "",
}

export default function About({ data: { allFile, file } }) {
  const { edges: images } = allFile
  const { childImageSharp } = file

  const [isSending, setIsSending] = useState(false)
  const [formState, setFormState] = useState(initialForm)

  const handleUserInputChange = e => {
    const target = e.target
    const name = target.name
    const value = target.value

    setFormState({ ...formState, [name]: value })
  }

  const onSubmit = async e => {
    setIsSending(true)
    e.preventDefault()
    const canSubmit = isValid(formState)
    if (canSubmit) {
      await saveContactForm(formState)
      console.log("success ", formState)
      setFormState(initialForm)
    } else {
      console.log("invalid ", formState)
    }
    setIsSending(false)
  }

  const isValid = data => {
    return Object.keys(data).every(key => {
      if (!!key || key === "hunny" || key === "phone") {
        return true
      }
    })
  }

  //ADD WEB EVENT
  function addWebEvent(event_type, event_name) {
    var data = new FormData();
    data.append("event_type", event_type);
    data.append("event_name", event_name);

    const queryString = typeof window !== "undefined" ? window.location.search : ""
    const urlParams = new URLSearchParams(queryString);
    var cid = urlParams.get("cid");
    data.append("cid", cid);

    var xhr = new XMLHttpRequest();
    xhr.open("POST", "https://api.checksolarcost.com/v1/addWebEvent");
    xhr.send(data);
  }
  useEffect(() => {
    addWebEvent("visit", "about")
  },[]);



  return (
    <Layout>
      <SEO title="About" />

      <HeroSection
        className="flex justify-center items-center text-white"
        fluid={childImageSharp.fluid}
      >
        <h1 className="tracking-wide font-light text-center">
          <span className="block border-b pb-8">Structure</span>
          <span className="block pt-4 font-bradford">Substance</span>
        </h1>
      </HeroSection>

      <StickyMobileCta></StickyMobileCta>

      {/*<LottieTest></LottieTest>*/}

      <section className="relative bg-white text-center lg:px-32 py-20 md:py-40 lg:border-l lg:border-r">
        <div className="lg:px-32 px-6 lg:border-l lg:border-r">
          <h3 className="m-auto max-w-4xl text-gray-500 font-bradford1 tracking-wide">
            <span className="font-bradford">At Modal,</span> we build small but
            smart. We're committed to provide a better way of life through
            modular, accessible, and responsible housing.
          </h3>
        </div>
      </section>

      <section className="relative">
        <Image
          className="absolute w-screen h-90 md:h-115"
          objectFit="cover"
          fluid={images[2].node.childImageSharp.fluid}
          alt="Modal About"
        />
        <div className="absolute inset-0 justify-end md:justify-center items-center lg:justify-between flex lg:flex-row flex-col flex-wrap pb-10 px-6 xl:px-40">
          <div className="feature-copy font-bradford1 font-thin text-white lg:pt-105 xl:pt-125 lg:mx-10">
            <p className="arrow-button pb-2 md:max-w-md lg:text-left text-center">
              <span className="font-bradford">Thoughtful & sustainable.</span>{" "}
              we put those things at the forefront of everything we do.
            </p>
          </div>
          <div className="items-center mt-10 lg:mt-0 flex justify-end border-b-2 border-white lg:pt-105 xl:pt-125 lg:mx-10 arrow-button">
            <CallToActionQualify
              to="/how-it-works"
              text="How it works"
              className="pb-2 tracking-wide font-light fill-current text-white"
            ></CallToActionQualify>
          </div>
        </div>
      </section>

      {/*<section className="relative" style={{ height: "55vh" }}>
        <Image
          className="absolute w-screen h-full"
          objectFit="cover"
          fluid={images[2].node.childImageSharp.fluid}
        />
        <div className="absolute inset-0 justify-center items-center lg:justify-between flex lg:flex-row flex-col flex-wrap lg:pt-90 lg:pb-10 px-6 xl:px-40">
          <div className="feature-copy font-bradford1 font-thin text-white">
            <p>Thoughtful & sustainable. we put those</p>
            <p>things at the forefront of everything we do.</p>
          </div>
          <div className="items-center mt-10 lg:mt-0 flex justify-end border-b-2 border-white">
            <CallToActionQualify
              to="/how-it-works"
              text="How it works"
              className="pb-2 tracking-wide font-light fill-current text-white"
            ></CallToActionQualify>
          </div>
        </div>
  </section>*/}

      <section className="relative bg-white lg:px-24 px-6">
        <div className="flex lg:flex-row flex-col bg-white flex-wrap justify-between items-center py-10 lg:py-40 text-gray-600 xl:max-w-modalXl m-auto">
          <div className="flex flex-1 items-center">
            <h2 className="md:pb-0 pb-10 text-gray-600 tracking-wide font-light">
              More than <span className="font-bradford">just</span> a name.
            </h2>
          </div>
          <div className="flex flex-1">
            <p className="text-gray-500 tracking-wide lg:pl-20 md:pt-10 lg:pt-0 font-light">
              Modal was created to make a difference. Our homes are sustainable,
              adaptable, affordable, and modern. We design unique spaces that
              are better for you and better for our planet. We build without
              compromising quality or functionality. Our product perfectly
              embodies our goal as an organization: to do more with less. We’re
              dedicated to providing a new mode of living.
            </p>
          </div>
        </div>
      </section>

      <section className="relative bg-white grid grid-cols-1 lg:grid-cols-2 col-gap-24 flex lg:flex-row flex-col justify-between items-center lg:pb-32 px-8 lg:px-24">
        <Image
          className="bg-bottom my-5 lg:my-0"
          fluid={images[1].node.childImageSharp.fluid}
          alt="about-modal-1"
        />

        <Image
          className="my-5 lg:my-0"
          fluid={images[0].node.childImageSharp.fluid}
          alt="about-modal-1"
        />
      </section>

      <div className="relative bg-white flex flex-col items-center pt-20 pb-24 md:pb-40">
        <h2 className="pb-5 text-gray-600 max-w-3xl text-center font-bradford1 tracking-wide px-8">
          "We're redefining high-end, low-cost, minimal living."
        </h2>
        <p className="text-gray-500 tracking-wide">Dallin Jolley Co-Founder</p>
      </div>

      
      <div className="hidden lg:block">
        <Marquee></Marquee>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query AboutImagesQuery {
    allFile(filter: { relativePath: { regex: "/modal-about/" } }) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 1100, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    file(relativePath: { eq: "modal-about-1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1500, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
